// composables/useAfterLogin.ts

import {useAuthStore} from "~/store/AuthStore";
import GetContainers from "~/packages/core/actions/config/GetContainers";
import GetContainersCdn from "~/packages/core/actions/config/GetContainersCdn";
import {useSettingsStore} from "~/store/SettingsStore";

export const useAfterLogin = () => {
    const { fetchPendingAnnouncements } = useAuth();
    const authStore = useAuthStore()
    const globalState = useSettingsStore();

    const showAnnouncementPopup = ref(false);
    const announcements = ref();

    const runAfterLoginTasks = async () => {
        if (authStore.justLoggedIn) {
            try {
                // Add actions you want to run once after login
                // reset containers every time the user is logging in
                globalState.setContainers(await GetContainers.execute());
                globalState.setContainersCdn(await GetContainersCdn.execute());

                // fetch pending announcements for current logged-in user
                announcements.value = await fetchPendingAnnouncements();

                // set announcements popup trigger if there are any announcements
                if(announcements.value?.length > 0) {
                    showAnnouncementPopup.value = true;
                }
            } catch (error) {
                useLog().error('Error during after-login tasks:', error)
            } finally {
                authStore.resetJustLoggedIn()
            }
        }
    }

    return { runAfterLoginTasks, showAnnouncementPopup, announcements }
}
